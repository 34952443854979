<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board">
              <template v-slot:tab>
                <ul class="tab">
                  <li v-for="tabItem in tabs" class="tab-item" :key="tabItem.id">
                    <button class="tab-btn"
                      :class="{ 'is-active': tabActive === tabItem.value }"
                      type="button" @click="tabChange(tabItem.value)"
                    >
                      {{ tabItem.name }}
                    </button>
                  </li>
                </ul>
              </template>
              <template v-slot:label>ポイントカテゴリー</template>
              <template v-slot:body>
                <div class="slide-body">
                  <transition name="slide" mode="out-in">
                    <div v-show="isQrPage" class="tab-content">
                      <FormRow>
                        <template v-slot:label>QRカテゴリー</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <div class="form-select">
                              <select
                                class="form-control form-select-input"
                                name="categoryId"
                                v-model="searchForm.categoryId"
                                @change="handleSearchCategory"
                              >
                                <option value="">選択してください</option>
                                <option
                                  v-for="item in categoriesList"
                                  :key="item.id"
                                  :value="item.id"
                                >{{ item.name }}</option>
                              </select>
                            </div>
                          </div>
                        </template>
                      </FormRow>
                      <section class="section">
                        <p class="form-label">QR登録一覧</p>
                        <div class="row">
                          <div class="col">
                            <Panel type="board" :isNotBorderShadow="true" :padding="false">
                              <template v-slot:body>
                                <div class="scrollX">
                                  <TableWithCheckbox
                                    ref="table"
                                    :hasTableBorder="true"
                                    :storeModule="storeModule"
                                    :dataTable="dataTable"
                                    :labels="labels"
                                    @handle-toggle="handleStatus"
                                    @select-items="handleSelectedItems"
                                    :count="qrTicketCount"
                                  />
                                </div>
                              </template>
                              <template v-slot:footer>
                                <PaginationLog
                                  ref="pagination"
                                  :modulePath="modulePath"
                                  :subdomain="subdomain"
                                  :listLength="qrTicketCount"
                                  :searchConditions="searchConditions"
                                ></PaginationLog>
                              </template>
                            </Panel>
                          </div>
                        </div>
                      </section>
                    </div>
                  </transition>
                  <transition name="slide" mode="out-in">
                    <div v-show="isCategoryPage" class="tab-content">
                      <Panel type="sheet" customClass="panel-point">
                        <template v-slot:headline>カテゴリー登録</template>
                        <template v-slot:body>
                          <form class="form-section category-add" :class="{ 'mb-0': isNoData }">
                            <FormRow :required="true">
                              <template v-slot:label>カテゴリー名</template>
                              <template v-slot:labelNote>（20文字以内）</template>
                              <template v-slot:content>
                                <div class="form-content-row">
                                  <input
                                    :class="{ 'form-control': true, 'is-error': errors['categoryName'] }"
                                    type="text"
                                    name="categoryName"
                                    maxlength="20"
                                    v-trim
                                    v-maxlength
                                    v-model="categoryName"
                                  />
                                </div>
                              </template>
                            </FormRow>
                            <ActionButton
                              v-if="isMaximumCategory"
                              class="btn btn-bd-main"
                              :handle-submit="addCategory"
                              button-text="追加"
                            />
                          </form>
                          <section class="section">
                            <div class="row">
                              <div class="col">
                                <Panel type="board" :padding="false">
                                  <template v-slot:headline>カテゴリー一覧</template>
                                  <template v-slot:headerContent
                                    ><Tooltip classNames="tooltip-left-offset" tooltipMsg="ドラッグ＆ドロップで並び替えできます"
                                  /></template>
                                  <template v-slot:body>
                                    <div class="scrollX category-table">
                                      <table class="dataTable sortable">
                                        <thead>
                                          <tr>
                                            <th>カテゴリー名</th>
                                            <th class="dataTable-fix"></th>
                                            <th class="dataTable-fix"></th>
                                          </tr>
                                        </thead>
                                        <Draggable
                                          :list="categoriesList"
                                          @change="handleSort"
                                          tag="tbody"
                                          :animation="300"
                                          handle=".sortable-handle"
                                        >
                                          <tr v-for="(item, index) in formData" :key="item.id">
                                            <td>
                                              <input
                                                class="form-control"
                                                :class="{ 'is-error': errors[`categoryName${index}`] }"
                                                type="text"
                                                name="name"
                                                v-model="item.name"
                                                @blur="updateCategory(item.id, item.name, index)"
                                                maxlength="20"
                                                v-maxlength
                                                v-trim
                                              />
                                            </td>
                                            <td>
                                              <button
                                                class="btn btn-bd-red"
                                                @click="handleCategoryDeleted(item.id)"
                                              >削除</button>
                                            </td>
                                            <td>
                                              <i class="aikon aikon-handle sortable-handle"></i>
                                            </td>
                                          </tr>
                                          <tr v-if="isNoData" class="noData">
                                            <td colspan="999">{{ noDataMessage }}</td>
                                          </tr>
                                        </Draggable>
                                      </table>
                                    </div>
                                  </template>
                                </Panel>
                              </div>
                            </div>
                          </section>
                        </template>
                      </Panel>
                    </div>
                  </transition>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <ModalConfirm
        title="選択したカテゴリーを削除しますか？"
        :handleCloseModal="() => closeModal('deletedCategoryModal')"
        :isConfirmModalShown="deletedCategoryModal"
        :handleConfirmModal="deleteCategory"
      />
    </div>
    <ModalQR
      :code="ticketQrDetail.encryptId"
      :name="ticketQrDetail.name"
      :handleCloseModal="closeModal"
      :isModalQrShown="modal"
      :isCloseDisabled="true"
      :isDownloadModalShown="true"
    />
    <ModalConfirm
      :handleCloseModal="() => closeModal('deletedModal')"
      :isConfirmModalShown="deletedModal"
      :handleConfirmModal="deleteTicketQRs"
    />
    <ModalConfirm
      :handleCloseModal="() => closeModal('statusModal', toggleFlag)"
      :headerTitle="isPublic ? '公開' : '非公開'"
      :title="isPublic ? 'このQRコードを公開しますか？' : 'このQRコードを非公開にしますか？'"
      :isConfirmModalShown="statusModal"
      :handleConfirmModal="changeStatus"
      :buttonText="isPublic ? '公開する' : '非公開にする'"
      color="yellow"
    />
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid" v-if="isQrPage">
          <li class="listGrid-item">
            <ActionButton :disabled="!isSelectedTicketQrExisted" class="btn btn-red" :handleSubmit="showDeleteModal" buttonText="削除" />
          </li>
          <li class="listGrid-item pos-end">
            <router-link class="btn btn-main" :to="{ name: 'EventCurrencyQrRegister' }">新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import { COMMON_MESSAGE } from '@/config/message';
//component
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import FormRow from '@/components/FormRow.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import ModalQR from '@/components/ModalQR.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
//mixins
import error from '@/mixins/plugin/error';
import nav from '@/mixins/nav/currency';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';
import category from '@/mixins/module/category';
//modal
import Tooltip from '@/components/Tooltip.vue';
import Draggable from 'vuedraggable';
//helpers
import {
  formatDateAndTime,
  endline,
  formatDashString,
  formatLocalString
} from '@/helpers/formatData';
import { copyToClipboard } from '@/helpers/common';

const qrPage = 1;
const categoryPage = 2;
const catagoryLimit = 10;

export default {
  name: 'EventCurrencyQr',
  data: function() {
    return {
      pageName: '通貨管理',
      categoryName: '',
      formData: [],
      tabActive: qrPage,
      categoryId: null,
      selectedTicketQR: {},
      searchConditions: {},
      maxCategoryCommon: 50,
      deletedModal: false,
      statusModal: false,
      deletedCategoryModal: false,
      qrPage,
      categoryPage,
      catagoryLimit,
      storeModule: 'currency',
      searchFields: ['categoryId'],
      ticketQrId: null,
      isPublic: 0,
      tabs: [
        { value: 1, name: '登録一覧' },
        { value: 2, name: 'カテゴリー登録' }
      ]
    };
  },
  components: {
    FormRow,
    PaginationLog,
    TableWithCheckbox,
    Tooltip,
    Draggable,
    ModalQR,
    ModalConfirm,
  },
  mixins: [modal, error, nav, search, category],
  computed: {
    ...mapGetters({
      isClientChild: 'auth/isClientChild',
      categoriesList: 'currency/categoriesList',
      qrTicketList: 'currency/qrTicketList',
      qrTicketCount: 'currency/qrTicketCount',
      ticketQrDetail: 'currency/ticketQrDetail',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    isQrPage() {
      return this.tabActive === this.qrPage;
    },
    isCategoryPage() {
      return this.tabActive === this.categoryPage;
    },
    isMaximumCategory() {
      return this.categoriesList?.length < this.maxCategoryCommon;
    },
    isNoData() {
      return !this.categoriesList?.length;
    },
    noDataMessage() {
      return this.unAuthorized ? COMMON_MESSAGE.unAuthorizedTable : COMMON_MESSAGE.noData;
    },
    labels() {
      return [
        { isCheckbox: true },
        { key: 'name', name: 'QRコード名称', tdClass: 'pre-line' },
        { key: 'createDate', name: '登録日時' },
        { key: 'issuer', name: '店舗名', tdClass: 'pre-line' },
        ...this.useTicketFlag ? [
          { key: 'ticket', name: 'TICKET名', tdClass: 'dataTable-break mw-120', keyMultiple: 'ticket' },
          { key: 'quantity', name: '口数/枚数', tdClass: 'dataTable-break', keyMultiple: 'ticket' },
        ] : [
          { key: 'type', name: '発行QR' },
          { key: 'amount', name: '金額' },
        ],
        { key: 'totalLimit', name: '利用制限' },
        { key: 'totalUse', name: '利用回数' },
        { key: 'validFlag', name: '公開' },
        { isButtonColumn: true }
      ]
    },
    dataTable() {
      return (
        (this.qrTicketList || []).map((item) => {
          return {
            ...item,
            isRowDisabled: !item?.canUse,
            name: {
              content: endline(item?.name, 10),
              routeName: 'EventCurrencyQrEdit',
              params: { id: item.id },
              isLink: true,
            },
            issuer: endline(item?.issuer, 10),
            createDate: formatDateAndTime(item.createDate),
            totalLimit: formatDashString(item?.totalLimit),
            totalUse: formatDashString(item?.totalUse),
            ticket: this.useTicketFlag ? this.extractNamesFromArray(item) : [],
            quantity: this.useTicketFlag ? this.extractNamesFromArray(item, 'quantity') : [],
            type: item?.type ? '消費（減算）' : '付与（加算）',
            amount: item?.amount ? (formatLocalString(item?.amount) + '円') : '',
            validFlag: {
              isToggleButton: true,
              value: item?.validFlag,
              id: item?.id,
            },
            buttonLinks: [
              {
                isNotShown: !item?.url,
                content: 'URLをコピー',
                class: 'btn btn-sm btn-black',
                function: () => copyToClipboard(item?.url, 'copyUrlSuccess'),
              },
              {
                content: 'QRコード',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.openQRModal(item.id),
              },
            ],
          };
        })
      )
    },
    modulePath() {
      const action = this.useTicketFlag ? 'getQrTicketList' : 'getCurrencyQrList';
      return `currency/${action}`;
    },
    isSelectedTicketQrExisted() {
      return this.selectedTicketQR?.ids?.length > 0 || this.selectedTicketQR.allFlag;
    },
  },
  validations: {
    categoryName: { required, maxLength: maxLength(20) },
  },
  watch: {
    categoriesList(nVal) {
      this.formData = nVal.map((el) => ({ ...el }));
    },
  },
  methods: {
    extractNamesFromArray(data, type = 'name') {
      if (this.useTicketFlag && !data.ticket?.length) return [];
        const customNames = (data || []).ticket.map((item, index) => {
        const notLastItem = index !== (data?.ticket.length - 1);
        const balanceUnit = data.type ? '' : `ｘ${item.unitBreakdown}枚`;
        const quantityUnit = !data.type ? '口' : '枚';
        const name = endline(item.name, 10);
        return {
          value: (type === 'name' ? `${name}\n${formatLocalString(item.amountBreakdown)}円${balanceUnit}` : `${item.quantity}${quantityUnit}`),
          class: `${notLastItem ? 'mb-2 ' : ''} ${type !== 'name' && (item.name?.length > 10 ? 'lh-60' : 'lh-40') }`,
        }
      });
      return customNames;
    },
    toggleFlag() {
      this.qrTicketList.find((el) => el.id === this.ticketQrId).validFlag = !this.isPublic;
    },
    handleStatus(id, newStatus) {
      this.showModal('statusModal');
      this.ticketQrId = id;
      this.qrTicketList.find((el) => el.id === id).validFlag = newStatus;
      this.isPublic = newStatus ? 1 : 0;
    },
    async changeStatus() {
      const action = this.useTicketFlag ? 'updateTicketQrStatus' : 'updateCurrencyQrStatus';
      const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
        subdomain: this.subdomain,
        id: this.ticketQrId,
        data: { validFlag: this.isPublic },
      });
      this.closeModal('statusModal');
      if (result) this.$message.updated('pointQR');
      else this.toggleFlag();
    },
    handleSelectedItems(items) {
      this.selectedTicketQR = items;
    },
    showDeleteModal() {
      if (this.isSelectedTicketQrExisted) {
        this.showModal('deletedModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    async deleteTicketQRs() {
      const action = this.useTicketFlag ? 'removeTicketQRs' : 'removeCurrencyQRs';
      const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
        ids: this.selectedTicketQR.ids,
        allFlag: this.selectedTicketQR.allFlag,
        searchCondition: {
          ...this.searchConditions,
        },
      });
      this.$refs.table.resetCheck();
      this.closeModal('deletedModal');
      if (result) {
        if (result.data.count) {
          this.$message.deleted('pointQR');
        } else {
          this.$message.showError('someoneUpdated');
        }
        this.$refs.pagination.resetPagination();
      }
    },
    async openQRModal(id) {
      const loading = this.$loading.show();
      const action = this.useTicketFlag ? 'getTicketQRDetail' : 'getCurrencyQRDetail';
      const ticketQRDetail = await this.$store.dispatch(`${this.storeModule}/${action}`, {
        id: id,
        subdomain: this.subdomain
      });
      this.$loading.clear(loading);
      const category = (this.categoriesList || []).find(item => item.id === ticketQRDetail.categoryId);
      this.showModal(null, {
        ...ticketQRDetail,
        pointCategoryName: category?.name || ''
      })
    },
    tabChange: async function(num) {
      this.tabActive = num;
      if (this.isQrPage) {
        this.$refs.pagination.resetPagination();
      } else {
        this.categoryName = '';
        this.getCategories();
      }
    },
    async updateCategory(id, name, index) {
      if (name === this.categoriesList[index].name) {
        this.$set(this.errors, `categoryName${index}`, false);
        return;
      } else if (this.checkExistCategoryName(name, this.categoriesList)) {
        this.$set(this.errors, `categoryName${index}`, true);
        this.$message.showError('duplicateCategory');
      } else {
        this.handleUpdateCategory(id, name, index, this.categoriesList, async() => {
          this.resetErrors(this.errors);
          const action = this.useTicketFlag ? 'updateCategory' : 'updateCurrencyCategory';
          const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
            subdomain: this.subdomain,
            categoryId: id,
            categoryName: name
          });
          if (result) {
            this.$message.updated('category');
            this.getCategories();
            this.$refs.pagination.resetPagination();
          }
        });
      }
    },
    async addCategory() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v, 'formCategory');
        return false;
      } else {
        this.errors = {};
        this.$message.reset();
        if (this.checkExistCategoryName(this.categoryName, this.categoriesList)) {
          this.errors['categoryName'] = true;
          const categoryMsg = this.useTicketFlag ? 'duplicateCategory' : 'duplicateCurrencyCategory';
          this.$message.showError(categoryMsg);
        } else {
          const action = this.useTicketFlag ? 'createCategory' : 'createCurrencyCategory';
          const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
            subdomain: this.subdomain,
            name: this.categoryName
          });
          if (result) {
            this.categoryName = '';
            this.getCategories();
            this.$message.created('category');
            this.$refs.pagination.resetPagination();
          }
        }
      }
    },
    handleCategoryDeleted(id) {
      this.categoryId = id;
      this.showModal('deletedCategoryModal');
    },
    async deleteCategory() {
      const action = this.useTicketFlag ? 'deleteCategory' : 'deleteCurrencyCategory';
      const result = await this.$store.dispatch(`${this.storeModule}/${action}`, {
        subdomain: this.subdomain,
        categoryId: this.categoryId,
      });
      this.closeModal('deletedCategoryModal');
      if (result) {
        this.getCategories();
        this.$message.deleted('category');
      }
    },
    handleSearchCategory() {
      this.search();
      if (this.qrTicketList?.length) {
        this.$refs.table.resetCheck();
      }
    },
    async handleSort(value) {
      const action = this.useTicketFlag ? 'sortCategory' : 'sortCurrencyCategory';
      this.handleSortCategory(value, 'position', 'position', this.categoriesList, `${this.storeModule}/${action}`, false);
    },
  },
  mounted() {
    const loading = this.$loading.show();
    this.categoryName = '';
    Promise.all([
      this.getCategories(),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  }
};
</script>

<style scoped>
.form-section {
  border-bottom: none;
  margin-bottom: 0;
}
.custom-no-data {
  margin: 20px 0;
}

.tab-content {
  margin-top: 8px;
}

.category-table {
  border: 1px solid #e1e2eb;
}
</style>
